import React from 'react'
import styleVariables from '~/layouts/variables.scss'

import { Container, Row, Col } from 'react-bootstrap'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import IconStripe from '~/images/icon-stripe-team.svg'
import GetWindowDimensions from '~/components/00-global/util/getWindowDimensions'

import './pageheader.scss'
const PageHeader = ({
  title,
  text,
  image,
  alt,
  xPos = 'center',
  yPos = 'center',
  xPosMob = 'center',
  yPosMob = 'center'
}) => {
  const md =
    typeof document !== `undefined` ? styleVariables.md.replace(/\D+/, '') : ''
  const { windowWidth } = GetWindowDimensions()

  return (
    <div className='pageheader'>
      <div className='svg-correct d-block d-md-none'>
        <svg>
          <clipPath
            id='clip-path-pageheader-mobile'
            clipPathUnits='objectBoundingBox'
          >
            <path d='M0,0.992 L0.05,0.989 L0.08,0.987 L0.14,0.987 L0.191,0.986 H0.208 L0.218,0.987 L0.245,0.987 L0.266,0.986 L0.298,0.984 L0.356,0.989 L0.401,0.986 L0.425,0.983 L0.479,0.98 H0.57 L0.627,0.983 L0.684,0.98 L0.754,0.983 L0.81,0.987 L0.85,0.992 L0.899,0.997 H0.929 L0.956,1 L1,0.992 V0 H0 V0.992'></path>
          </clipPath>
        </svg>
      </div>
      <div className='svg-correct d-none d-md-block'>
        <svg>
          <clipPath id='clip-path-pageheader' clipPathUnits='objectBoundingBox'>
            <path d='M0,0.978 L0.05,0.968 L0.08,0.964 L0.14,0.964 L0.191,0.96 H0.208 L0.218,0.963 L0.245,0.963 L0.266,0.96 L0.298,0.954 L0.356,0.968 L0.401,0.96 L0.425,0.952 L0.479,0.944 H0.57 L0.627,0.953 L0.684,0.945 L0.754,0.953 L0.81,0.964 L0.85,0.978 L0.899,0.991 H0.929 L0.956,1 L1,0.978 V0 H0 V0.978'></path>
          </clipPath>
        </svg>
      </div>
      <div className='shadow-wrap'>
        <BackgroundImage
          className='clipped'
          fluid={image}
          alt={alt}
          style={
            windowWidth < md
              ? {
                  backgroundPosition: `${xPosMob} ${yPosMob} `
                }
              : { backgroundPosition: `${xPos} ${yPos} ` }
          }
        >
          <Container>
            <div className='content'>
              <h1>
                {title}
                <div className='stripe'>
                  <img src={IconStripe} alt='' />
                </div>
              </h1>
              <p className='order-md-1'>{text}</p>
            </div>
          </Container>
        </BackgroundImage>
      </div>
    </div>
  )
}

export default PageHeader
