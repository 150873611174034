import React from 'react'
import SEO from '~/components/00-global/util/seo'
import PageHeader from '~/components/00-global/PageHeader/PageHeader'
import { graphql, useStaticQuery } from 'gatsby'
import Infos from '~/components/06-infos/Infos'
import { useIntl } from 'gatsby-plugin-intl'
import { Load } from '~/components/00-global/Animation/Animation'

const InfosPage = () => {
  const intl = useIntl()
  const { imagePageHeader, infos } = useStaticQuery(graphql`
    query {
      imagePageHeader: file(
        relativePath: { eq: "image-biersafe-showcase.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      infos: allContentfulInfo {
        nodes {
          id
          title
          type {
            type
          }
          richText {
            raw
            # references {
            #   ... on ContentfulAsset {
            #     contentful_id
            #     __typename
            #     title
            #     file {
            #       url
            #     }
            #   }
            # }
          }
          node_locale
        }
      }
    }
  `)
  return (
    <>
      <Load>
        <SEO
          title={intl.formatMessage({ id: 'infos.header.title' })}
          description={intl.formatMessage({ id: 'infos.header.subtitle' })}
          pathname={`/infos`}
        />
        <PageHeader
          title={intl.formatMessage({ id: 'infos.header.title' })}
          text={intl.formatMessage({ id: 'infos.header.subtitle' })}
          image={imagePageHeader.childImageSharp.fluid}
          alt={intl.formatMessage({ id: 'infos.header.image' })}
        />
        <Infos infos={infos.nodes} />
      </Load>
    </>
  )
}

export default InfosPage
