import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './Infos.module.scss'
import Img from 'gatsby-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import {
  Accordion,
  Container,
  Card,
  Row,
  Col,
  useAccordionToggle
} from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Lottie from 'lottie-react-web'
import { INLINES } from '@contentful/rich-text-types'

// Icons
import IconShovel from '~/images/icon-shovel.svg'
import IconGloves from '~/images/icon-gloves.svg'
import IconThumb from '~/images/icon-thumb.svg'
import Collapse from '~/json/collapse-toggle'

import { Scroll } from '~/components/00-global/Animation/Animation'

const ContextAwareToggle = ({ eventKey, title }) => {
  const currentEventKey = useContext(AccordionContext)
  const open = currentEventKey === eventKey

  const handleClick = useAccordionToggle(eventKey)

  return (
    <button className={styles.button} onClick={handleClick}>
      <h3>{title}</h3>
      <div className={styles.lottieContainer}>
        <Lottie
          tabIndex='-1'
          isPaused={false}
          direction={open ? 1 : -1}
          speed={2}
          options={{
            animationData: Collapse,
            loop: false
          }}
        />
      </div>
    </button>
  )
}

const Infos = ({ infos }) => {
  const intl = useIntl()
  const { image1, image2 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "image-man-on-field.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(relativePath: { eq: "image-biersafes-in-the-grass.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const infosAssembly = infos.filter(info => info.type.type === 'Einbau')
  const infosTips = infos.filter(info => info.type.type === 'Tipps')

  const richTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        return (
          <a href={node.data.uri} target='_blank'>
            {node.content[0].value}
          </a>
        )
      },
      [INLINES.ASSET_HYPERLINK]: node => {
        return (
          <a href={node.data.target.file.url} target='_blank'>
            {node.content[0].value}
          </a>
        )
      }
    }
  }

  return (
    <>
      <section className={styles.section}>
        <Container fluid='xxl' className={styles.container}>
          <Row className={styles.rowHeading}>
            <Col className={styles.colImage} xs='12' md='6'>
              <Img
                className={styles.image}
                fluid={image1.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'infos.assembly.image' })}
              />
            </Col>
            <Col className={styles.colText} xs='12' md='6'>
              <div className={styles.headingContainer}>
                <div className='overflow-hidden'>
                  <Scroll>
                    <h2>
                      <FormattedMessage id='infos.assembly.title' />
                    </h2>
                  </Scroll>
                </div>
                <img
                  className={styles.shovel}
                  src={IconShovel}
                  alt='Schaufel'
                />
                <img
                  className={styles.gloves}
                  src={IconGloves}
                  alt='Handschuhe'
                />
              </div>
              <p className={styles.subheading}>
                <FormattedMessage id='infos.assembly.subtitle' />
              </p>
              <p>
                <FormattedMessage id='infos.assembly.text' />
              </p>
            </Col>
          </Row>
          {infosAssembly
            .filter(info =>
              intl.locale === 'de'
                ? info.node_locale === 'de'
                : info.node_locale === 'en'
            )
            .map(info => (
              <Accordion key={info.id} className={styles.accordion}>
                <Scroll>
                  <Card className={styles.card}>
                    <ContextAwareToggle eventKey={info.id} title={info.title} />
                    <Accordion.Collapse eventKey={info.id}>
                      <Card.Body className={styles.cardBody} as='article'>
                        {renderRichText(info.richText, richTextOptions)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Scroll>
              </Accordion>
            ))}
        </Container>
      </section>

      <section className={styles.section}>
        <Container fluid='xxl' className={styles.container}>
          <Row className={styles.rowHeading}>
            <Col className={styles.colText}>
              <div className={styles.headingContainer}>
                <div className='overflow-hidden'>
                  <Scroll>
                    <h2>
                      <FormattedMessage id='infos.tips.title' />
                    </h2>
                  </Scroll>
                </div>

                <img className={styles.thumb} src={IconThumb} alt='Daumen' />
              </div>
              <p className={styles.subheading}>
                <FormattedMessage id='infos.tips.subtitle' />
              </p>
              <p>
                <FormattedMessage id='infos.tips.text' />
              </p>
            </Col>
            <Col className={styles.colImage} xs='12' md='6'>
              <Img
                className={styles.image}
                fluid={image2.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'infos.tips.image' })}
              />
            </Col>
          </Row>
          {infosTips
            .filter(info =>
              intl.locale === 'de'
                ? info.node_locale === 'de'
                : info.node_locale === 'en'
            )
            .map(info => (
              <Accordion key={info.id} className={styles.accordion}>
                <Scroll>
                  <Card className={styles.card}>
                    <ContextAwareToggle eventKey={info.id} title={info.title} />
                    <Accordion.Collapse eventKey={info.id}>
                      <Card.Body className={styles.cardBody} as='article'>
                        {renderRichText(info.richText, richTextOptions)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Scroll>
              </Accordion>
            ))}
        </Container>
      </section>
    </>
  )
}

export default Infos
